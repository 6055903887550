<template>
  <div
    class="relative flex w-full overflow-x-clip p-4 md:p-3"
    :class="{
      'bg-background-extra-light md:rounded-xl': !transparentBg,
      'h-full flex-col': !horizontal,
    }"
    data-test-id="product-card"
  >
    <div :class="{ 'mr-4 flex-1': horizontal }">
      <img
        v-if="isVetRecommendedBadgeEnabled && isVetRecommended"
        class="absolute right-2 top-2 z-10 size-11 md:size-12"
        src="~/assets/images/vet-like-stamp.svg"
      />
      <img
        v-if="isFrozenFood"
        class="absolute right-2 top-2 z-10 size-11 md:size-12"
        :src="FrozenFood"
      />
      <ProductCampaignBadge
        v-if="campaign"
        :amount="campaign.amount"
        :type="campaign.type"
        :size="campaignBadgeSize"
        :should-overflow="campaignBadgeShouldOverflow"
        :color-class="
          blackWeekEnabledLocales.includes(locale)
            ? 'text-black/87'
            : 'bg-supporting-price'
        "
      />

      <button
        v-if="showDeleteButton"
        class="absolute right-4 z-10 cursor-pointer"
        @click="$emit('trigger-delete', id)"
      >
        <DynamicPictogram name="trash" size="lg" style="padding: 6px 0" />
      </button>

      <FavoriteToggle
        v-if="
          !showDeleteButton &&
          !isCartRecommendation &&
          !isRxProduct &&
          $i18n.locale === 'uk'
        "
        :product-id="id"
        :tracking-data="{
          name: title,
          price: specialPrice || regularPrice,
        }"
        class="absolute right-4 top-4 z-10"
      />

      <OptimizedImage
        :src="imageSrc"
        :alt="title"
        class="mx-auto mb-4 size-40 object-contain mix-blend-darken"
        :class="imageClasses"
        width="160"
        height="160"
        sizes="xs:160px"
        :lazy="shouldLazyLoadImage"
      />
    </div>
    <div class="flex flex-col" :class="horizontal ? 'w-2/3' : 'h-full'">
      <div class="flex flex-wrap items-center text-black/54">
        <template v-if="hasBrand">
          <component
            :is="disableLinks ? 'span' : nuxtLink"
            :to="
              localePath({
                name: 'shop-brands-slug',
                params: { slug: brand.slug },
              })
            "
            class="hover:underline"
            data-test-id="brand"
          >
            {{ brand.name }}
          </component>
        </template>
        <template v-else-if="isRxProduct">
          <span> {{ $t('prescription.prescription_product') }} </span>
        </template>
        <!-- <template v-if="showAnimalTypes">
          <span v-if="hasBrand && animalTypes.length" class="mx-2">-</span>
          <span v-if="!disableLinks">
            <NuxtLink
              v-for="(animalType, index) in animalTypes"
              :key="animalType.entityId"
              :to="localePath(`/${shopName}${animalType.path}`)"
              class="mr-1 hover:underline"
            >
              {{
                animalType.name + (index !== animalTypes.length - 1 ? ',' : '')
              }}
            </NuxtLink>
          </span>
          <span v-if="disableLinks">
            <span
              v-for="(animalType, index) in animalTypes"
              :key="animalType.entityId"
              class="mr-1 hover:underline"
            >
              {{
                animalType.name + (index !== animalTypes.length - 1 ? ',' : '')
              }}
            </span>
          </span>
        </template> -->
      </div>
      <h6 class="overflow-hidden pb-2 text-lg">
        <span v-if="!disableLinks">
          <NuxtLink
            :to="productPath"
            class="product-link line-clamp-2 hover:underline"
            data-test-id="product-link"
            :class="{
              'cover-parent': !disableLinks,
              'line-clamp-none': isRxProduct,
            }"
            @click="trackProductClick"
          >
            {{ title }}
          </NuxtLink>
        </span>
        <span v-if="disableLinks">
          <span
            class="product-link line-clamp-2 hover:underline"
            data-test-id="product-link"
            :class="{
              'cover-parent': !disableLinks,
              'line-clamp-none': isRxProduct,
            }"
          >
            {{ title }}
          </span>
        </span>
      </h6>
      <div class="pb-5 sm:pb-7">
        <span
          v-for="option in options"
          :key="option.id"
          class="scrollbar-hidden flex overflow-auto"
        >
          <template v-for="optionValue in option.values">
            <Badge
              v-if="optionValue.label.toLowerCase() !== title.toLowerCase()"
              :key="optionValue.id"
              :to="
                disableLinks
                  ? ''
                  : localeRoute({
                      name: 'shop-product-slug',
                      params: { slug },
                      query: { options: option.id, values: optionValue.id },
                    })
              "
              class="mb-1 mr-1"
              :label="optionValue.label"
            />
          </template>
        </span>
      </div>
      <ProductCardBuyButton
        class="buy-button mt-auto"
        :link="productPath"
        :slug="slug"
        :is-buy-button-link-to-product="isBuyButtonLinkToProduct"
        :special-price="specialPrice"
        :regular-price="regularPrice"
        :show-from-price="showFromPrice"
        :qty-in-cart="qtyInCart"
        :show-added-to-cart="showAddedToCart"
        :variants="variants"
        :enable-variant-select="enableVariantSelect"
        :is-rx-product="isRxProduct"
        @click-link="trackProductClick"
        @click-button="handleButtonClick"
      />
    </div>

    <Teleport v-if="showAddedToCartModal" to="body">
      <AddedToCartDialog
        :is-added-to-cart-dialog-open="showAddedToCartModal"
        :product="productStub"
        :images="addedToCartImages"
        :subscription-frequency="{ value: 0, label: '' }"
        class="fixed"
        @update:is-added-to-cart-dialog-open="
          (isOpen: boolean) => (showAddedToCartModal = isOpen)
        "
      />
    </Teleport>

    <LazyBrazeAddToCartEvent v-if="shouldSendBrazeAddToCartEvent" />
  </div>
</template>

<script setup lang="ts">
import productImagePlaceholder from '~/assets/images/product-image-placeholder.png';
import PrescriptionProductImage from '~/assets/images/prescription-product-image.svg?url';
import FrozenFood from '~/assets/images/frozen-food.svg?url';
import {
  productClickEvent,
  productTrackingData,
  addToCartEvent,
} from '@/utils/tracking-utils';
import { useCartStore } from '~/stores/cart';

const { blackWeekEnabledLocales, isVetRecommendedBadgeEnabled } =
  useRuntimeConfig().public.featureFlags;

const localePath = useLocalePath();
const router = useRouter();
const { locale } = useI18n();
const gtm = useGtm();

const cartStore = useCartStore();
const subscriptionStore = useSubscriptionStore();

const { itemCount } = storeToRefs(cartStore);
const { subscriptionItemCount } = storeToRefs(subscriptionStore);

const props = defineProps({
  id: { type: Number, default: null },
  title: { type: String, default: '' },
  link: { type: String, default: '' },
  slug: { type: String, default: '' },
  disableLinks: { type: Boolean, default: false },
  campaign: { type: Object, default: () => ({}) },
  regularPrice: { type: [String, Number], default: '' },
  specialPrice: { type: [String, Number], default: '' },
  isCheapestVariantPrice: { type: Boolean, default: true },
  enableVariantSelect: { type: Boolean, default: false },
  image: { type: String, default: '' },
  imageClasses: { type: String, default: '' },
  brand: { type: Object, default: () => ({ path: '', name: '' }) },
  options: { type: Array, default: () => [] },
  variants: { type: Array, default: () => [] },
  categoryIds: { type: Array, default: () => [] },
  isAvailable: { type: Boolean, default: true },
  isCartRecommendation: { type: Boolean, default: false },
  showAnimalTypes: { type: Boolean, default: true },
  trackingData: { type: Object, default: () => ({}) },
  productClickCallback: { type: Function, default: () => {} },
  addToCartCallback: { type: Function, default: () => {} },
  horizontal: { type: Boolean, default: false },
  transparentBg: { type: Boolean, default: false },
  campaignBadgeShouldOverflow: { type: Boolean, default: true },
  showDeleteButton: { type: Boolean, default: false },
  campaignBadgeSize: { type: String, default: 'md' },
  shouldLazyLoadImage: { type: Boolean, default: true },
  isRxProduct: { type: Boolean, default: false },
  isVetRecommended: { type: Boolean, default: false },
  isFrozenFood: { type: Boolean, default: false },
});

defineEmits(['trigger-delete']);

const quickBuyBtnLoading = ref(false);
const quickBuyBtnClicked = ref(false);

const nuxtLink = computed(() => resolveComponent('NuxtLink'));

// This has not been working on production for some time so commenting out for now to also
// be able to reduce the initial state
// const shopName = computed(
//   () => useShopCountryConfig()?.routes?.[locale.value].shop
// );
// const animalTypes = computed(() => {
//   const { showAnimalTypes } = props;
//   const { categoryIds } = props;

//   if (showAnimalTypes && categoryIds?.length) {
//     return navMenuStore.getAnimalTypesForCategories(categoryIds);
//   }

//   return [];
// });

const showAddedToCartModal = ref(false);

const productStub = computed(() => ({
  name: props.title,
  brand: props.brand,
}));

const shouldSendBrazeAddToCartEvent = computed(
  () => cartStore.shouldSendBrazeAddToCartEventForProductId === props.id
);

const hasBrand = computed(() => props.brand?.slug && props.brand?.name);

const imageSrc = computed(() => {
  if (props.image && props.image.includes('prescription-product-image.svg')) {
    return PrescriptionProductImage;
  }
  return props.image || productImagePlaceholder;
});

const addedToCartImages = computed(() => [
  { src: imageSrc.value, alt: props.title },
]);

const hasMultipleVariants = computed(() => props.variants?.length > 1);

const singleVariantId = computed(() =>
  props.variants?.length === 1 ? props.variants?.[0]?.id : null
);

const showFromPrice = computed(
  () => hasMultipleVariants.value && props.isCheapestVariantPrice
);

const showAddedToCart = computed(
  () => quickBuyBtnLoading.value || quickBuyBtnClicked.value
);

const isBuyButtonLinkToProduct = computed(
  () =>
    (hasMultipleVariants.value ||
      !singleVariantId.value ||
      !props.isAvailable) &&
    !props.isRxProduct
);

const qtyInCart = computed(() =>
  props.id && singleVariantId.value
    ? cartStore.getItemInCart(props.id, singleVariantId.value)?.quantity
    : 0
);

const productPath = computed(() => {
  if (props.disableLinks) {
    return '';
  }

  if (props.isRxProduct) {
    const parsedSlugs = props.slug?.split('/');
    if (parsedSlugs.length === 2) {
      const brandSlug = parsedSlugs[0];
      const itemNumber = parsedSlugs[1];
      return localePath({
        name: 'shop-prescription-brand-slug',
        params: { brand: brandSlug, slug: itemNumber },
      });
    }
    return '';
  }

  return localePath({
    name: 'shop-product-slug',
    params: { slug: props.slug },
  });
});

const sendTrackingEvent = (event) => {
  const { product, position, list, extraFields } = props.trackingData;
  const products = [
    {
      ...productTrackingData(
        {
          ...product,
          variantId: singleVariantId.value,
        },
        position
      ),
      ...(extraFields || {}),
    },
  ];
  gtm?.trackEvent(event(products, locale.value, list));
};

const handleButtonClick = (value) => {
  if (props.isRxProduct) {
    return router.push(localePath({ name: 'shop-dashboard-prescriptions' }));
  }
  return quickBuy(value);
};

const quickBuy = async (value) => {
  const variantId = value || singleVariantId.value;

  if (props.id && variantId && !props.disableLinks) {
    quickBuyBtnLoading.value = true;
    quickBuyBtnClicked.value = true;

    // Make sure 'added to cart' text is visible for at least 1400 ms in case loading time is very short
    setTimeout(() => {
      quickBuyBtnClicked.value = false;
    }, 1400);

    await cartStore.addToCart({
      cartItems: [
        {
          item: {
            quantity: 1,
            productId: props.id,
            variantId,
          },
          meta: {
            isOnSale: !!props.campaign?.amount,
            categoryIds: props.categoryIds,
            brand: props?.brand,
          },
        },
      ],
    });

    quickBuyBtnLoading.value = false;

    props.addToCartCallback();

    if (itemCount.value === 1 && subscriptionItemCount.value === 0) {
      showAddedToCartModal.value = true;
    }

    sendTrackingEvent(addToCartEvent);
  }
};

const trackProductClick = () => {
  if (props.disableLinks) {
    return;
  }

  props.productClickCallback();

  sendTrackingEvent(productClickEvent);
};
</script>

<style lang="postcss" scoped>
@media (min-width: theme('screens.sm')) {
  a {
    z-index: 2;
  }
}
</style>
