<template>
  <button @click="addToFavorites(productId)">
    <div class="relative" data-test-id="favorite-icon">
      <Heart
        class="pictogram favorite-icon favorite-svg size-6 cursor-pointer overflow-visible"
        :class="isFavorite ? 'is-favorite' : ''"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import Heart from '~/assets/pictograms/dynamic/heart.svg?component';
import { useFavoriteStore } from '~/stores/favorites';

const favoritesStore = useFavoriteStore();
const { locale } = useI18n();
const gtm = useGtm();

const props = defineProps({
  productId: {
    type: Number,
    default: 0,
  },
  trackingData: {
    type: Object,
    default: () => {},
  },
});

const favorites = computed(() => favoritesStore.favorites);

const isFavorite = computed(() => favorites.value.includes(props.productId));

const removeFavorite = (productId: number) =>
  favoritesStore.removeFavorite(productId);
const addFavorite = (productId: number) =>
  favoritesStore.addFavorite(productId);

const addToFavorites = (productId: number) => {
  if (!favorites.value.includes(productId)) {
    addFavorite(productId);

    const { name, price } = props.trackingData;
    gtm?.trackEvent(
      addToFavoritesEvent(name, price, locale.value as LocaleTerritory)
    );
  } else {
    removeFavorite(productId);
  }
};
</script>

<style lang="postcss">
.favorite-icon path {
  @apply stroke-black/54 fill-transparent;
  transform-origin: center;
}

.is-favorite path {
  animation: favorite-animation 0.5s cubic-bezier(0.17, 0.89, 0.32, 1.49)
    forwards;
}

@keyframes favorite-animation {
  0% {
    transform: scale(0);
  }
  14% {
    @apply fill-supporting-dark-3 stroke-supporting-dark-3;
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
    @apply fill-supporting-dark-3 stroke-supporting-dark-3;
  }
}
</style>
